
// @ts-nocheck
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_about_json from "../json/languages/ar/ar.about.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_announcements_json from "../json/languages/ar/ar.announcements.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_awards_json from "../json/languages/ar/ar.awards.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_contact_json from "../json/languages/ar/ar.contact.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_crops_json from "../json/languages/ar/ar.crops.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_crops_resources_json from "../json/languages/ar/ar.crops.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_extras_json from "../json/languages/ar/ar.extras.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_extraterms_json from "../json/languages/ar/ar.extraterms.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_history_json from "../json/languages/ar/ar.history.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_home_json from "../json/languages/ar/ar.home.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_json from "../json/languages/ar/ar.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_legal_json from "../json/languages/ar/ar.legal.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_nav_json from "../json/languages/ar/ar.nav.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_productfamilies_json from "../json/languages/ar/ar.productfamilies.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_productfamily_resources_json from "../json/languages/ar/ar.productfamily.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_products_json from "../json/languages/ar/ar.products.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_products_plates_json from "../json/languages/ar/ar.products.plates.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_products_resources_json from "../json/languages/ar/ar.products.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_resources_json from "../json/languages/ar/ar.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_solutions_automation_control_json from "../json/languages/ar/ar.solutions.automation-control.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_solutions_field_crops_json from "../json/languages/ar/ar.solutions.field-crops.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_solutions_high_volume_json from "../json/languages/ar/ar.solutions.high-volume.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_solutions_json from "../json/languages/ar/ar.solutions.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_solutions_mechanized_irrigation_json from "../json/languages/ar/ar.solutions.mechanized-irrigation.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_solutions_non_agricultural_applications_json from "../json/languages/ar/ar.solutions.non-agricultural-applications.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_solutions_nursery_json from "../json/languages/ar/ar.solutions.nursery.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_solutions_resources_json from "../json/languages/ar/ar.solutions.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_solutions_sustainability_json from "../json/languages/ar/ar.solutions.sustainability.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_solutions_tree_and_vine_json from "../json/languages/ar/ar.solutions.tree-and-vine.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_storymodules_json from "../json/languages/ar/ar.storymodules.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_webtools_json from "../json/languages/ar/ar.webtools.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_about_json from "../json/languages/de/de.about.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_announcements_json from "../json/languages/de/de.announcements.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_awards_json from "../json/languages/de/de.awards.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_contact_json from "../json/languages/de/de.contact.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_crops_json from "../json/languages/de/de.crops.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_crops_resources_json from "../json/languages/de/de.crops.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_extras_json from "../json/languages/de/de.extras.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_extraterms_json from "../json/languages/de/de.extraterms.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_history_json from "../json/languages/de/de.history.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_home_json from "../json/languages/de/de.home.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_json from "../json/languages/de/de.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_legal_json from "../json/languages/de/de.legal.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_nav_json from "../json/languages/de/de.nav.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_productfamilies_json from "../json/languages/de/de.productfamilies.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_productfamily_resources_json from "../json/languages/de/de.productfamily.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_products_json from "../json/languages/de/de.products.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_products_plates_json from "../json/languages/de/de.products.plates.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_products_resources_json from "../json/languages/de/de.products.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_resources_json from "../json/languages/de/de.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_solutions_automation_control_json from "../json/languages/de/de.solutions.automation-control.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_solutions_field_crops_json from "../json/languages/de/de.solutions.field-crops.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_solutions_high_volume_json from "../json/languages/de/de.solutions.high-volume.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_solutions_json from "../json/languages/de/de.solutions.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_solutions_mechanized_irrigation_json from "../json/languages/de/de.solutions.mechanized-irrigation.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_solutions_non_agricultural_applications_json from "../json/languages/de/de.solutions.non-agricultural-applications.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_solutions_nursery_json from "../json/languages/de/de.solutions.nursery.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_solutions_resources_json from "../json/languages/de/de.solutions.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_solutions_sustainability_json from "../json/languages/de/de.solutions.sustainability.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_solutions_tree_and_vine_json from "../json/languages/de/de.solutions.tree-and-vine.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_storymodules_json from "../json/languages/de/de.storymodules.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_webtools_json from "../json/languages/de/de.webtools.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_json from "../json/languages/en/en.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_about_json from "../json/languages/en/en.about.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_announcements_json from "../json/languages/en/en.announcements.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_awards_json from "../json/languages/en/en.awards.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_contact_json from "../json/languages/en/en.contact.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_crops_json from "../json/languages/en/en.crops.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_crops_resources_json from "../json/languages/en/en.crops.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_extras_json from "../json/languages/en/en.extras.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_extraterms_json from "../json/languages/en/en.extraterms.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_history_json from "../json/languages/en/en.history.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_home_json from "../json/languages/en/en.home.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_legal_json from "../json/languages/en/en.legal.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_nav_json from "../json/languages/en/en.nav.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_productfamilies_json from "../json/languages/en/en.productfamilies.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_productfamily_resources_json from "../json/languages/en/en.productfamily.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_products_json from "../json/languages/en/en.products.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_products_plates_json from "../json/languages/en/en.products.plates.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_products_resources_json from "../json/languages/en/en.products.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_resources_json from "../json/languages/en/en.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_solutions_automation_control_json from "../json/languages/en/en.solutions.automation-control.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_solutions_field_crops_json from "../json/languages/en/en.solutions.field-crops.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_solutions_high_volume_json from "../json/languages/en/en.solutions.high-volume.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_solutions_json from "../json/languages/en/en.solutions.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_solutions_mechanized_irrigation_json from "../json/languages/en/en.solutions.mechanized-irrigation.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_solutions_non_agricultural_applications_json from "../json/languages/en/en.solutions.non-agricultural-applications.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_solutions_nursery_json from "../json/languages/en/en.solutions.nursery.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_solutions_resources_json from "../json/languages/en/en.solutions.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_solutions_sustainability_json from "../json/languages/en/en.solutions.sustainability.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_solutions_tree_and_vine_json from "../json/languages/en/en.solutions.tree-and-vine.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_storymodules_json from "../json/languages/en/en.storymodules.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_webtools_json from "../json/languages/en/en.webtools.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_about_json from "../json/languages/es/es.about.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_announcements_json from "../json/languages/es/es.announcements.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_awards_json from "../json/languages/es/es.awards.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_contact_json from "../json/languages/es/es.contact.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_crops_json from "../json/languages/es/es.crops.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_crops_resources_json from "../json/languages/es/es.crops.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_extras_json from "../json/languages/es/es.extras.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_extraterms_json from "../json/languages/es/es.extraterms.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_history_json from "../json/languages/es/es.history.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_home_json from "../json/languages/es/es.home.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_json from "../json/languages/es/es.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_legal_json from "../json/languages/es/es.legal.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_nav_json from "../json/languages/es/es.nav.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_productfamilies_json from "../json/languages/es/es.productfamilies.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_productfamily_resources_json from "../json/languages/es/es.productfamily.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_products_json from "../json/languages/es/es.products.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_products_plates_json from "../json/languages/es/es.products.plates.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_products_resources_json from "../json/languages/es/es.products.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_resources_json from "../json/languages/es/es.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_solutions_automation_control_json from "../json/languages/es/es.solutions.automation-control.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_solutions_field_crops_json from "../json/languages/es/es.solutions.field-crops.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_solutions_high_volume_json from "../json/languages/es/es.solutions.high-volume.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_solutions_json from "../json/languages/es/es.solutions.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_solutions_mechanized_irrigation_json from "../json/languages/es/es.solutions.mechanized-irrigation.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_solutions_non_agricultural_applications_json from "../json/languages/es/es.solutions.non-agricultural-applications.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_solutions_nursery_json from "../json/languages/es/es.solutions.nursery.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_solutions_resources_json from "../json/languages/es/es.solutions.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_solutions_sustainability_json from "../json/languages/es/es.solutions.sustainability.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_solutions_tree_and_vine_json from "../json/languages/es/es.solutions.tree-and-vine.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_storymodules_json from "../json/languages/es/es.storymodules.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_webtools_json from "../json/languages/es/es.webtools.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_about_json from "../json/languages/fr/fr.about.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_announcements_json from "../json/languages/fr/fr.announcements.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_awards_json from "../json/languages/fr/fr.awards.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_contact_json from "../json/languages/fr/fr.contact.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_crops_json from "../json/languages/fr/fr.crops.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_crops_resources_json from "../json/languages/fr/fr.crops.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_extras_json from "../json/languages/fr/fr.extras.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_extraterms_json from "../json/languages/fr/fr.extraterms.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_history_json from "../json/languages/fr/fr.history.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_home_json from "../json/languages/fr/fr.home.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_json from "../json/languages/fr/fr.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_legal_json from "../json/languages/fr/fr.legal.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_nav_json from "../json/languages/fr/fr.nav.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_productfamilies_json from "../json/languages/fr/fr.productfamilies.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_productfamily_resources_json from "../json/languages/fr/fr.productfamily.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_products_json from "../json/languages/fr/fr.products.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_products_plates_json from "../json/languages/fr/fr.products.plates.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_products_resources_json from "../json/languages/fr/fr.products.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_resources_json from "../json/languages/fr/fr.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_solutions_automation_control_json from "../json/languages/fr/fr.solutions.automation-control.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_solutions_field_crops_json from "../json/languages/fr/fr.solutions.field-crops.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_solutions_high_volume_json from "../json/languages/fr/fr.solutions.high-volume.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_solutions_json from "../json/languages/fr/fr.solutions.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_solutions_mechanized_irrigation_json from "../json/languages/fr/fr.solutions.mechanized-irrigation.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_solutions_non_agricultural_applications_json from "../json/languages/fr/fr.solutions.non-agricultural-applications.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_solutions_nursery_json from "../json/languages/fr/fr.solutions.nursery.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_solutions_resources_json from "../json/languages/fr/fr.solutions.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_solutions_sustainability_json from "../json/languages/fr/fr.solutions.sustainability.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_solutions_tree_and_vine_json from "../json/languages/fr/fr.solutions.tree-and-vine.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_storymodules_json from "../json/languages/fr/fr.storymodules.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_webtools_json from "../json/languages/fr/fr.webtools.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_about_json from "../json/languages/hu/hu.about.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_announcements_json from "../json/languages/hu/hu.announcements.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_awards_json from "../json/languages/hu/hu.awards.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_contact_json from "../json/languages/hu/hu.contact.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_crops_json from "../json/languages/hu/hu.crops.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_crops_resources_json from "../json/languages/hu/hu.crops.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_extras_json from "../json/languages/hu/hu.extras.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_extraterms_json from "../json/languages/hu/hu.extraterms.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_history_json from "../json/languages/hu/hu.history.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_home_json from "../json/languages/hu/hu.home.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_legal_json from "../json/languages/hu/hu.legal.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_nav_json from "../json/languages/hu/hu.nav.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_productfamilies_json from "../json/languages/hu/hu.productfamilies.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_productfamily_resources_json from "../json/languages/hu/hu.productfamily.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_products_json from "../json/languages/hu/hu.products.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_products_plates_json from "../json/languages/hu/hu.products.plates.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_products_resources_json from "../json/languages/hu/hu.products.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_resources_json from "../json/languages/hu/hu.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_solutions_automation_control_json from "../json/languages/hu/hu.solutions.automation-control.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_solutions_field_crops_json from "../json/languages/hu/hu.solutions.field-crops.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_solutions_high_volume_json from "../json/languages/hu/hu.solutions.high-volume.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_solutions_json from "../json/languages/hu/hu.solutions.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_solutions_non_agricultural_applications_json from "../json/languages/hu/hu.solutions.non-agricultural-applications.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_solutions_nursery_json from "../json/languages/hu/hu.solutions.nursery.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_solutions_resources_json from "../json/languages/hu/hu.solutions.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_solutions_sustainability_json from "../json/languages/hu/hu.solutions.sustainability.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_solutions_tree_and_vine_json from "../json/languages/hu/hu.solutions.tree-and-vine.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_about_json from "../json/languages/pt/pt.about.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_announcements_json from "../json/languages/pt/pt.announcements.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_awards_json from "../json/languages/pt/pt.awards.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_contact_json from "../json/languages/pt/pt.contact.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_crops_json from "../json/languages/pt/pt.crops.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_crops_resources_json from "../json/languages/pt/pt.crops.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_extras_json from "../json/languages/pt/pt.extras.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_extraterms_json from "../json/languages/pt/pt.extraterms.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_history_json from "../json/languages/pt/pt.history.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_home_json from "../json/languages/pt/pt.home.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_json from "../json/languages/pt/pt.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_legal_json from "../json/languages/pt/pt.legal.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_nav_json from "../json/languages/pt/pt.nav.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_productfamilies_json from "../json/languages/pt/pt.productfamilies.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_productfamily_resources_json from "../json/languages/pt/pt.productfamily.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_products_json from "../json/languages/pt/pt.products.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_products_plates_json from "../json/languages/pt/pt.products.plates.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_products_resources_json from "../json/languages/pt/pt.products.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_resources_json from "../json/languages/pt/pt.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_solutions_automation_control_json from "../json/languages/pt/pt.solutions.automation-control.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_solutions_field_crops_json from "../json/languages/pt/pt.solutions.field-crops.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_solutions_high_volume_json from "../json/languages/pt/pt.solutions.high-volume.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_solutions_json from "../json/languages/pt/pt.solutions.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_solutions_mechanized_irrigation_json from "../json/languages/pt/pt.solutions.mechanized-irrigation.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_solutions_non_agricultural_applications_json from "../json/languages/pt/pt.solutions.non-agricultural-applications.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_solutions_nursery_json from "../json/languages/pt/pt.solutions.nursery.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_solutions_resources_json from "../json/languages/pt/pt.solutions.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_solutions_sustainability_json from "../json/languages/pt/pt.solutions.sustainability.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_solutions_tree_and_vine_json from "../json/languages/pt/pt.solutions.tree-and-vine.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_storymodules_json from "../json/languages/pt/pt.storymodules.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_webtools_json from "../json/languages/pt/pt.webtools.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_about_json from "../json/languages/ru/ru.about.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_announcements_json from "../json/languages/ru/ru.announcements.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_awards_json from "../json/languages/ru/ru.awards.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_contact_json from "../json/languages/ru/ru.contact.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_crops_json from "../json/languages/ru/ru.crops.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_crops_resources_json from "../json/languages/ru/ru.crops.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_extras_json from "../json/languages/ru/ru.extras.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_extraterms_json from "../json/languages/ru/ru.extraterms.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_history_json from "../json/languages/ru/ru.history.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_home_json from "../json/languages/ru/ru.home.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_json from "../json/languages/ru/ru.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_legal_json from "../json/languages/ru/ru.legal.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_nav_json from "../json/languages/ru/ru.nav.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_productfamilies_json from "../json/languages/ru/ru.productfamilies.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_productfamily_resources_json from "../json/languages/ru/ru.productfamily.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_products_json from "../json/languages/ru/ru.products.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_products_plates_json from "../json/languages/ru/ru.products.plates.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_products_resources_json from "../json/languages/ru/ru.products.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_resources_json from "../json/languages/ru/ru.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_solutions_automation_control_json from "../json/languages/ru/ru.solutions.automation-control.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_solutions_field_crops_json from "../json/languages/ru/ru.solutions.field-crops.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_solutions_high_volume_json from "../json/languages/ru/ru.solutions.high-volume.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_solutions_json from "../json/languages/ru/ru.solutions.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_solutions_mechanized_irrigation_json from "../json/languages/ru/ru.solutions.mechanized-irrigation.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_solutions_non_agricultural_applications_json from "../json/languages/ru/ru.solutions.non-agricultural-applications.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_solutions_nursery_json from "../json/languages/ru/ru.solutions.nursery.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_solutions_resources_json from "../json/languages/ru/ru.solutions.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_solutions_sustainability_json from "../json/languages/ru/ru.solutions.sustainability.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_solutions_tree_and_vine_json from "../json/languages/ru/ru.solutions.tree-and-vine.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_storymodules_json from "../json/languages/ru/ru.storymodules.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_webtools_json from "../json/languages/ru/ru.webtools.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_about_json from "../json/languages/tr/tr.about.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_announcements_json from "../json/languages/tr/tr.announcements.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_awards_json from "../json/languages/tr/tr.awards.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_contact_json from "../json/languages/tr/tr.contact.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_crops_json from "../json/languages/tr/tr.crops.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_crops_resources_json from "../json/languages/tr/tr.crops.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_extras_json from "../json/languages/tr/tr.extras.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_extraterms_json from "../json/languages/tr/tr.extraterms.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_history_json from "../json/languages/tr/tr.history.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_home_json from "../json/languages/tr/tr.home.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_json from "../json/languages/tr/tr.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_legal_json from "../json/languages/tr/tr.legal.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_nav_json from "../json/languages/tr/tr.nav.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_productfamilies_json from "../json/languages/tr/tr.productfamilies.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_productfamily_resources_json from "../json/languages/tr/tr.productfamily.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_products_json from "../json/languages/tr/tr.products.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_products_plates_json from "../json/languages/tr/tr.products.plates.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_products_resources_json from "../json/languages/tr/tr.products.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_resources_json from "../json/languages/tr/tr.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_solutions_automation_control_json from "../json/languages/tr/tr.solutions.automation-control.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_solutions_field_crops_json from "../json/languages/tr/tr.solutions.field-crops.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_solutions_high_volume_json from "../json/languages/tr/tr.solutions.high-volume.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_solutions_json from "../json/languages/tr/tr.solutions.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_solutions_mechanized_irrigation_json from "../json/languages/tr/tr.solutions.mechanized-irrigation.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_solutions_non_agricultural_applications_json from "../json/languages/tr/tr.solutions.non-agricultural-applications.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_solutions_nursery_json from "../json/languages/tr/tr.solutions.nursery.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_solutions_resources_json from "../json/languages/tr/tr.solutions.resources.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_solutions_sustainability_json from "../json/languages/tr/tr.solutions.sustainability.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_solutions_tree_and_vine_json from "../json/languages/tr/tr.solutions.tree-and-vine.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_storymodules_json from "../json/languages/tr/tr.storymodules.json";
import locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_webtools_json from "../json/languages/tr/tr.webtools.json";


export const localeCodes =  [
  "ar",
  "de",
  "en",
  "es",
  "fr",
  "hu",
  "pt",
  "ru",
  "tr"
]

export const localeLoaders = {
  "ar": [{ key: "../json/languages/ar/ar.about.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_about_json), cache: true },
{ key: "../json/languages/ar/ar.announcements.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_announcements_json), cache: true },
{ key: "../json/languages/ar/ar.awards.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_awards_json), cache: true },
{ key: "../json/languages/ar/ar.contact.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_contact_json), cache: true },
{ key: "../json/languages/ar/ar.crops.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_crops_json), cache: true },
{ key: "../json/languages/ar/ar.crops.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_crops_resources_json), cache: true },
{ key: "../json/languages/ar/ar.extras.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_extras_json), cache: true },
{ key: "../json/languages/ar/ar.extraterms.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_extraterms_json), cache: true },
{ key: "../json/languages/ar/ar.history.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_history_json), cache: true },
{ key: "../json/languages/ar/ar.home.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_home_json), cache: true },
{ key: "../json/languages/ar/ar.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_json), cache: true },
{ key: "../json/languages/ar/ar.legal.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_legal_json), cache: true },
{ key: "../json/languages/ar/ar.nav.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_nav_json), cache: true },
{ key: "../json/languages/ar/ar.productfamilies.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_productfamilies_json), cache: true },
{ key: "../json/languages/ar/ar.productfamily.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_productfamily_resources_json), cache: true },
{ key: "../json/languages/ar/ar.products.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_products_json), cache: true },
{ key: "../json/languages/ar/ar.products.plates.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_products_plates_json), cache: true },
{ key: "../json/languages/ar/ar.products.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_products_resources_json), cache: true },
{ key: "../json/languages/ar/ar.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_resources_json), cache: true },
{ key: "../json/languages/ar/ar.solutions.automation-control.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_solutions_automation_control_json), cache: true },
{ key: "../json/languages/ar/ar.solutions.field-crops.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_solutions_field_crops_json), cache: true },
{ key: "../json/languages/ar/ar.solutions.high-volume.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_solutions_high_volume_json), cache: true },
{ key: "../json/languages/ar/ar.solutions.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_solutions_json), cache: true },
{ key: "../json/languages/ar/ar.solutions.mechanized-irrigation.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_solutions_mechanized_irrigation_json), cache: true },
{ key: "../json/languages/ar/ar.solutions.non-agricultural-applications.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_solutions_non_agricultural_applications_json), cache: true },
{ key: "../json/languages/ar/ar.solutions.nursery.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_solutions_nursery_json), cache: true },
{ key: "../json/languages/ar/ar.solutions.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_solutions_resources_json), cache: true },
{ key: "../json/languages/ar/ar.solutions.sustainability.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_solutions_sustainability_json), cache: true },
{ key: "../json/languages/ar/ar.solutions.tree-and-vine.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_solutions_tree_and_vine_json), cache: true },
{ key: "../json/languages/ar/ar.storymodules.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_storymodules_json), cache: true },
{ key: "../json/languages/ar/ar.webtools.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ar_ar_webtools_json), cache: true }],
  "de": [{ key: "../json/languages/de/de.about.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_about_json), cache: true },
{ key: "../json/languages/de/de.announcements.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_announcements_json), cache: true },
{ key: "../json/languages/de/de.awards.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_awards_json), cache: true },
{ key: "../json/languages/de/de.contact.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_contact_json), cache: true },
{ key: "../json/languages/de/de.crops.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_crops_json), cache: true },
{ key: "../json/languages/de/de.crops.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_crops_resources_json), cache: true },
{ key: "../json/languages/de/de.extras.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_extras_json), cache: true },
{ key: "../json/languages/de/de.extraterms.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_extraterms_json), cache: true },
{ key: "../json/languages/de/de.history.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_history_json), cache: true },
{ key: "../json/languages/de/de.home.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_home_json), cache: true },
{ key: "../json/languages/de/de.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_json), cache: true },
{ key: "../json/languages/de/de.legal.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_legal_json), cache: true },
{ key: "../json/languages/de/de.nav.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_nav_json), cache: true },
{ key: "../json/languages/de/de.productfamilies.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_productfamilies_json), cache: true },
{ key: "../json/languages/de/de.productfamily.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_productfamily_resources_json), cache: true },
{ key: "../json/languages/de/de.products.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_products_json), cache: true },
{ key: "../json/languages/de/de.products.plates.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_products_plates_json), cache: true },
{ key: "../json/languages/de/de.products.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_products_resources_json), cache: true },
{ key: "../json/languages/de/de.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_resources_json), cache: true },
{ key: "../json/languages/de/de.solutions.automation-control.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_solutions_automation_control_json), cache: true },
{ key: "../json/languages/de/de.solutions.field-crops.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_solutions_field_crops_json), cache: true },
{ key: "../json/languages/de/de.solutions.high-volume.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_solutions_high_volume_json), cache: true },
{ key: "../json/languages/de/de.solutions.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_solutions_json), cache: true },
{ key: "../json/languages/de/de.solutions.mechanized-irrigation.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_solutions_mechanized_irrigation_json), cache: true },
{ key: "../json/languages/de/de.solutions.non-agricultural-applications.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_solutions_non_agricultural_applications_json), cache: true },
{ key: "../json/languages/de/de.solutions.nursery.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_solutions_nursery_json), cache: true },
{ key: "../json/languages/de/de.solutions.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_solutions_resources_json), cache: true },
{ key: "../json/languages/de/de.solutions.sustainability.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_solutions_sustainability_json), cache: true },
{ key: "../json/languages/de/de.solutions.tree-and-vine.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_solutions_tree_and_vine_json), cache: true },
{ key: "../json/languages/de/de.storymodules.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_storymodules_json), cache: true },
{ key: "../json/languages/de/de.webtools.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_de_de_webtools_json), cache: true }],
  "en": [{ key: "../json/languages/en/en.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_json), cache: true },
{ key: "../json/languages/en/en.about.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_about_json), cache: true },
{ key: "../json/languages/en/en.announcements.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_announcements_json), cache: true },
{ key: "../json/languages/en/en.awards.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_awards_json), cache: true },
{ key: "../json/languages/en/en.contact.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_contact_json), cache: true },
{ key: "../json/languages/en/en.crops.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_crops_json), cache: true },
{ key: "../json/languages/en/en.crops.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_crops_resources_json), cache: true },
{ key: "../json/languages/en/en.extras.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_extras_json), cache: true },
{ key: "../json/languages/en/en.extraterms.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_extraterms_json), cache: true },
{ key: "../json/languages/en/en.history.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_history_json), cache: true },
{ key: "../json/languages/en/en.home.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_home_json), cache: true },
{ key: "../json/languages/en/en.legal.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_legal_json), cache: true },
{ key: "../json/languages/en/en.nav.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_nav_json), cache: true },
{ key: "../json/languages/en/en.productfamilies.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_productfamilies_json), cache: true },
{ key: "../json/languages/en/en.productfamily.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_productfamily_resources_json), cache: true },
{ key: "../json/languages/en/en.products.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_products_json), cache: true },
{ key: "../json/languages/en/en.products.plates.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_products_plates_json), cache: true },
{ key: "../json/languages/en/en.products.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_products_resources_json), cache: true },
{ key: "../json/languages/en/en.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_resources_json), cache: true },
{ key: "../json/languages/en/en.solutions.automation-control.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_solutions_automation_control_json), cache: true },
{ key: "../json/languages/en/en.solutions.field-crops.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_solutions_field_crops_json), cache: true },
{ key: "../json/languages/en/en.solutions.high-volume.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_solutions_high_volume_json), cache: true },
{ key: "../json/languages/en/en.solutions.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_solutions_json), cache: true },
{ key: "../json/languages/en/en.solutions.mechanized-irrigation.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_solutions_mechanized_irrigation_json), cache: true },
{ key: "../json/languages/en/en.solutions.non-agricultural-applications.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_solutions_non_agricultural_applications_json), cache: true },
{ key: "../json/languages/en/en.solutions.nursery.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_solutions_nursery_json), cache: true },
{ key: "../json/languages/en/en.solutions.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_solutions_resources_json), cache: true },
{ key: "../json/languages/en/en.solutions.sustainability.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_solutions_sustainability_json), cache: true },
{ key: "../json/languages/en/en.solutions.tree-and-vine.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_solutions_tree_and_vine_json), cache: true },
{ key: "../json/languages/en/en.storymodules.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_storymodules_json), cache: true },
{ key: "../json/languages/en/en.webtools.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_en_en_webtools_json), cache: true }],
  "es": [{ key: "../json/languages/es/es.about.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_about_json), cache: true },
{ key: "../json/languages/es/es.announcements.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_announcements_json), cache: true },
{ key: "../json/languages/es/es.awards.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_awards_json), cache: true },
{ key: "../json/languages/es/es.contact.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_contact_json), cache: true },
{ key: "../json/languages/es/es.crops.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_crops_json), cache: true },
{ key: "../json/languages/es/es.crops.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_crops_resources_json), cache: true },
{ key: "../json/languages/es/es.extras.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_extras_json), cache: true },
{ key: "../json/languages/es/es.extraterms.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_extraterms_json), cache: true },
{ key: "../json/languages/es/es.history.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_history_json), cache: true },
{ key: "../json/languages/es/es.home.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_home_json), cache: true },
{ key: "../json/languages/es/es.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_json), cache: true },
{ key: "../json/languages/es/es.legal.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_legal_json), cache: true },
{ key: "../json/languages/es/es.nav.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_nav_json), cache: true },
{ key: "../json/languages/es/es.productfamilies.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_productfamilies_json), cache: true },
{ key: "../json/languages/es/es.productfamily.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_productfamily_resources_json), cache: true },
{ key: "../json/languages/es/es.products.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_products_json), cache: true },
{ key: "../json/languages/es/es.products.plates.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_products_plates_json), cache: true },
{ key: "../json/languages/es/es.products.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_products_resources_json), cache: true },
{ key: "../json/languages/es/es.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_resources_json), cache: true },
{ key: "../json/languages/es/es.solutions.automation-control.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_solutions_automation_control_json), cache: true },
{ key: "../json/languages/es/es.solutions.field-crops.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_solutions_field_crops_json), cache: true },
{ key: "../json/languages/es/es.solutions.high-volume.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_solutions_high_volume_json), cache: true },
{ key: "../json/languages/es/es.solutions.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_solutions_json), cache: true },
{ key: "../json/languages/es/es.solutions.mechanized-irrigation.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_solutions_mechanized_irrigation_json), cache: true },
{ key: "../json/languages/es/es.solutions.non-agricultural-applications.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_solutions_non_agricultural_applications_json), cache: true },
{ key: "../json/languages/es/es.solutions.nursery.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_solutions_nursery_json), cache: true },
{ key: "../json/languages/es/es.solutions.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_solutions_resources_json), cache: true },
{ key: "../json/languages/es/es.solutions.sustainability.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_solutions_sustainability_json), cache: true },
{ key: "../json/languages/es/es.solutions.tree-and-vine.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_solutions_tree_and_vine_json), cache: true },
{ key: "../json/languages/es/es.storymodules.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_storymodules_json), cache: true },
{ key: "../json/languages/es/es.webtools.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_es_es_webtools_json), cache: true }],
  "fr": [{ key: "../json/languages/fr/fr.about.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_about_json), cache: true },
{ key: "../json/languages/fr/fr.announcements.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_announcements_json), cache: true },
{ key: "../json/languages/fr/fr.awards.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_awards_json), cache: true },
{ key: "../json/languages/fr/fr.contact.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_contact_json), cache: true },
{ key: "../json/languages/fr/fr.crops.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_crops_json), cache: true },
{ key: "../json/languages/fr/fr.crops.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_crops_resources_json), cache: true },
{ key: "../json/languages/fr/fr.extras.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_extras_json), cache: true },
{ key: "../json/languages/fr/fr.extraterms.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_extraterms_json), cache: true },
{ key: "../json/languages/fr/fr.history.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_history_json), cache: true },
{ key: "../json/languages/fr/fr.home.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_home_json), cache: true },
{ key: "../json/languages/fr/fr.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_json), cache: true },
{ key: "../json/languages/fr/fr.legal.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_legal_json), cache: true },
{ key: "../json/languages/fr/fr.nav.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_nav_json), cache: true },
{ key: "../json/languages/fr/fr.productfamilies.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_productfamilies_json), cache: true },
{ key: "../json/languages/fr/fr.productfamily.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_productfamily_resources_json), cache: true },
{ key: "../json/languages/fr/fr.products.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_products_json), cache: true },
{ key: "../json/languages/fr/fr.products.plates.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_products_plates_json), cache: true },
{ key: "../json/languages/fr/fr.products.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_products_resources_json), cache: true },
{ key: "../json/languages/fr/fr.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_resources_json), cache: true },
{ key: "../json/languages/fr/fr.solutions.automation-control.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_solutions_automation_control_json), cache: true },
{ key: "../json/languages/fr/fr.solutions.field-crops.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_solutions_field_crops_json), cache: true },
{ key: "../json/languages/fr/fr.solutions.high-volume.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_solutions_high_volume_json), cache: true },
{ key: "../json/languages/fr/fr.solutions.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_solutions_json), cache: true },
{ key: "../json/languages/fr/fr.solutions.mechanized-irrigation.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_solutions_mechanized_irrigation_json), cache: true },
{ key: "../json/languages/fr/fr.solutions.non-agricultural-applications.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_solutions_non_agricultural_applications_json), cache: true },
{ key: "../json/languages/fr/fr.solutions.nursery.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_solutions_nursery_json), cache: true },
{ key: "../json/languages/fr/fr.solutions.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_solutions_resources_json), cache: true },
{ key: "../json/languages/fr/fr.solutions.sustainability.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_solutions_sustainability_json), cache: true },
{ key: "../json/languages/fr/fr.solutions.tree-and-vine.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_solutions_tree_and_vine_json), cache: true },
{ key: "../json/languages/fr/fr.storymodules.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_storymodules_json), cache: true },
{ key: "../json/languages/fr/fr.webtools.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_fr_fr_webtools_json), cache: true }],
  "hu": [{ key: "../json/languages/hu/hu.about.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_about_json), cache: true },
{ key: "../json/languages/hu/hu.announcements.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_announcements_json), cache: true },
{ key: "../json/languages/hu/hu.awards.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_awards_json), cache: true },
{ key: "../json/languages/hu/hu.contact.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_contact_json), cache: true },
{ key: "../json/languages/hu/hu.crops.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_crops_json), cache: true },
{ key: "../json/languages/hu/hu.crops.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_crops_resources_json), cache: true },
{ key: "../json/languages/hu/hu.extras.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_extras_json), cache: true },
{ key: "../json/languages/hu/hu.extraterms.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_extraterms_json), cache: true },
{ key: "../json/languages/hu/hu.history.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_history_json), cache: true },
{ key: "../json/languages/hu/hu.home.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_home_json), cache: true },
{ key: "../json/languages/hu/hu.legal.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_legal_json), cache: true },
{ key: "../json/languages/hu/hu.nav.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_nav_json), cache: true },
{ key: "../json/languages/hu/hu.productfamilies.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_productfamilies_json), cache: true },
{ key: "../json/languages/hu/hu.productfamily.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_productfamily_resources_json), cache: true },
{ key: "../json/languages/hu/hu.products.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_products_json), cache: true },
{ key: "../json/languages/hu/hu.products.plates.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_products_plates_json), cache: true },
{ key: "../json/languages/hu/hu.products.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_products_resources_json), cache: true },
{ key: "../json/languages/hu/hu.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_resources_json), cache: true },
{ key: "../json/languages/hu/hu.solutions.automation-control.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_solutions_automation_control_json), cache: true },
{ key: "../json/languages/hu/hu.solutions.field-crops.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_solutions_field_crops_json), cache: true },
{ key: "../json/languages/hu/hu.solutions.high-volume.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_solutions_high_volume_json), cache: true },
{ key: "../json/languages/hu/hu.solutions.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_solutions_json), cache: true },
{ key: "../json/languages/hu/hu.solutions.non-agricultural-applications.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_solutions_non_agricultural_applications_json), cache: true },
{ key: "../json/languages/hu/hu.solutions.nursery.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_solutions_nursery_json), cache: true },
{ key: "../json/languages/hu/hu.solutions.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_solutions_resources_json), cache: true },
{ key: "../json/languages/hu/hu.solutions.sustainability.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_solutions_sustainability_json), cache: true },
{ key: "../json/languages/hu/hu.solutions.tree-and-vine.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_hu_hu_solutions_tree_and_vine_json), cache: true }],
  "pt": [{ key: "../json/languages/pt/pt.about.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_about_json), cache: true },
{ key: "../json/languages/pt/pt.announcements.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_announcements_json), cache: true },
{ key: "../json/languages/pt/pt.awards.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_awards_json), cache: true },
{ key: "../json/languages/pt/pt.contact.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_contact_json), cache: true },
{ key: "../json/languages/pt/pt.crops.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_crops_json), cache: true },
{ key: "../json/languages/pt/pt.crops.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_crops_resources_json), cache: true },
{ key: "../json/languages/pt/pt.extras.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_extras_json), cache: true },
{ key: "../json/languages/pt/pt.extraterms.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_extraterms_json), cache: true },
{ key: "../json/languages/pt/pt.history.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_history_json), cache: true },
{ key: "../json/languages/pt/pt.home.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_home_json), cache: true },
{ key: "../json/languages/pt/pt.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_json), cache: true },
{ key: "../json/languages/pt/pt.legal.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_legal_json), cache: true },
{ key: "../json/languages/pt/pt.nav.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_nav_json), cache: true },
{ key: "../json/languages/pt/pt.productfamilies.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_productfamilies_json), cache: true },
{ key: "../json/languages/pt/pt.productfamily.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_productfamily_resources_json), cache: true },
{ key: "../json/languages/pt/pt.products.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_products_json), cache: true },
{ key: "../json/languages/pt/pt.products.plates.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_products_plates_json), cache: true },
{ key: "../json/languages/pt/pt.products.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_products_resources_json), cache: true },
{ key: "../json/languages/pt/pt.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_resources_json), cache: true },
{ key: "../json/languages/pt/pt.solutions.automation-control.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_solutions_automation_control_json), cache: true },
{ key: "../json/languages/pt/pt.solutions.field-crops.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_solutions_field_crops_json), cache: true },
{ key: "../json/languages/pt/pt.solutions.high-volume.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_solutions_high_volume_json), cache: true },
{ key: "../json/languages/pt/pt.solutions.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_solutions_json), cache: true },
{ key: "../json/languages/pt/pt.solutions.mechanized-irrigation.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_solutions_mechanized_irrigation_json), cache: true },
{ key: "../json/languages/pt/pt.solutions.non-agricultural-applications.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_solutions_non_agricultural_applications_json), cache: true },
{ key: "../json/languages/pt/pt.solutions.nursery.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_solutions_nursery_json), cache: true },
{ key: "../json/languages/pt/pt.solutions.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_solutions_resources_json), cache: true },
{ key: "../json/languages/pt/pt.solutions.sustainability.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_solutions_sustainability_json), cache: true },
{ key: "../json/languages/pt/pt.solutions.tree-and-vine.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_solutions_tree_and_vine_json), cache: true },
{ key: "../json/languages/pt/pt.storymodules.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_storymodules_json), cache: true },
{ key: "../json/languages/pt/pt.webtools.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_pt_pt_webtools_json), cache: true }],
  "ru": [{ key: "../json/languages/ru/ru.about.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_about_json), cache: true },
{ key: "../json/languages/ru/ru.announcements.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_announcements_json), cache: true },
{ key: "../json/languages/ru/ru.awards.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_awards_json), cache: true },
{ key: "../json/languages/ru/ru.contact.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_contact_json), cache: true },
{ key: "../json/languages/ru/ru.crops.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_crops_json), cache: true },
{ key: "../json/languages/ru/ru.crops.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_crops_resources_json), cache: true },
{ key: "../json/languages/ru/ru.extras.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_extras_json), cache: true },
{ key: "../json/languages/ru/ru.extraterms.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_extraterms_json), cache: true },
{ key: "../json/languages/ru/ru.history.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_history_json), cache: true },
{ key: "../json/languages/ru/ru.home.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_home_json), cache: true },
{ key: "../json/languages/ru/ru.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_json), cache: true },
{ key: "../json/languages/ru/ru.legal.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_legal_json), cache: true },
{ key: "../json/languages/ru/ru.nav.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_nav_json), cache: true },
{ key: "../json/languages/ru/ru.productfamilies.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_productfamilies_json), cache: true },
{ key: "../json/languages/ru/ru.productfamily.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_productfamily_resources_json), cache: true },
{ key: "../json/languages/ru/ru.products.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_products_json), cache: true },
{ key: "../json/languages/ru/ru.products.plates.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_products_plates_json), cache: true },
{ key: "../json/languages/ru/ru.products.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_products_resources_json), cache: true },
{ key: "../json/languages/ru/ru.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_resources_json), cache: true },
{ key: "../json/languages/ru/ru.solutions.automation-control.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_solutions_automation_control_json), cache: true },
{ key: "../json/languages/ru/ru.solutions.field-crops.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_solutions_field_crops_json), cache: true },
{ key: "../json/languages/ru/ru.solutions.high-volume.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_solutions_high_volume_json), cache: true },
{ key: "../json/languages/ru/ru.solutions.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_solutions_json), cache: true },
{ key: "../json/languages/ru/ru.solutions.mechanized-irrigation.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_solutions_mechanized_irrigation_json), cache: true },
{ key: "../json/languages/ru/ru.solutions.non-agricultural-applications.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_solutions_non_agricultural_applications_json), cache: true },
{ key: "../json/languages/ru/ru.solutions.nursery.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_solutions_nursery_json), cache: true },
{ key: "../json/languages/ru/ru.solutions.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_solutions_resources_json), cache: true },
{ key: "../json/languages/ru/ru.solutions.sustainability.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_solutions_sustainability_json), cache: true },
{ key: "../json/languages/ru/ru.solutions.tree-and-vine.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_solutions_tree_and_vine_json), cache: true },
{ key: "../json/languages/ru/ru.storymodules.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_storymodules_json), cache: true },
{ key: "../json/languages/ru/ru.webtools.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_ru_ru_webtools_json), cache: true }],
  "tr": [{ key: "../json/languages/tr/tr.about.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_about_json), cache: true },
{ key: "../json/languages/tr/tr.announcements.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_announcements_json), cache: true },
{ key: "../json/languages/tr/tr.awards.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_awards_json), cache: true },
{ key: "../json/languages/tr/tr.contact.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_contact_json), cache: true },
{ key: "../json/languages/tr/tr.crops.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_crops_json), cache: true },
{ key: "../json/languages/tr/tr.crops.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_crops_resources_json), cache: true },
{ key: "../json/languages/tr/tr.extras.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_extras_json), cache: true },
{ key: "../json/languages/tr/tr.extraterms.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_extraterms_json), cache: true },
{ key: "../json/languages/tr/tr.history.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_history_json), cache: true },
{ key: "../json/languages/tr/tr.home.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_home_json), cache: true },
{ key: "../json/languages/tr/tr.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_json), cache: true },
{ key: "../json/languages/tr/tr.legal.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_legal_json), cache: true },
{ key: "../json/languages/tr/tr.nav.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_nav_json), cache: true },
{ key: "../json/languages/tr/tr.productfamilies.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_productfamilies_json), cache: true },
{ key: "../json/languages/tr/tr.productfamily.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_productfamily_resources_json), cache: true },
{ key: "../json/languages/tr/tr.products.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_products_json), cache: true },
{ key: "../json/languages/tr/tr.products.plates.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_products_plates_json), cache: true },
{ key: "../json/languages/tr/tr.products.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_products_resources_json), cache: true },
{ key: "../json/languages/tr/tr.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_resources_json), cache: true },
{ key: "../json/languages/tr/tr.solutions.automation-control.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_solutions_automation_control_json), cache: true },
{ key: "../json/languages/tr/tr.solutions.field-crops.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_solutions_field_crops_json), cache: true },
{ key: "../json/languages/tr/tr.solutions.high-volume.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_solutions_high_volume_json), cache: true },
{ key: "../json/languages/tr/tr.solutions.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_solutions_json), cache: true },
{ key: "../json/languages/tr/tr.solutions.mechanized-irrigation.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_solutions_mechanized_irrigation_json), cache: true },
{ key: "../json/languages/tr/tr.solutions.non-agricultural-applications.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_solutions_non_agricultural_applications_json), cache: true },
{ key: "../json/languages/tr/tr.solutions.nursery.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_solutions_nursery_json), cache: true },
{ key: "../json/languages/tr/tr.solutions.resources.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_solutions_resources_json), cache: true },
{ key: "../json/languages/tr/tr.solutions.sustainability.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_solutions_sustainability_json), cache: true },
{ key: "../json/languages/tr/tr.solutions.tree-and-vine.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_solutions_tree_and_vine_json), cache: true },
{ key: "../json/languages/tr/tr.storymodules.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_storymodules_json), cache: true },
{ key: "../json/languages/tr/tr.webtools.json", load: () => Promise.resolve(locale_C_58_C_58_Users_brettp_Documents_Workspaces_nelsonweb_nuxt_json_languages_tr_tr_webtools_json), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.js?hash=c231e734&config=1" /* webpackChunkName: "__i18n_config_js_c231e734" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.js",
  "locales": [
    {
      "code": "ar",
      "language": "ar",
      "name": "العربية",
      "files": [
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.about.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.announcements.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.awards.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.contact.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.crops.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.crops.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.extras.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.extraterms.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.history.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.home.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.legal.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.nav.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.productfamilies.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.productfamily.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.products.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.products.plates.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.products.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.solutions.automation-control.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.solutions.field-crops.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.solutions.high-volume.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.solutions.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.solutions.mechanized-irrigation.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.solutions.non-agricultural-applications.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.solutions.nursery.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.solutions.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.solutions.sustainability.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.solutions.tree-and-vine.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.storymodules.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.webtools.json"
      ],
      "dir": "rtl"
    },
    {
      "code": "de",
      "language": "de",
      "name": "Deutsch",
      "files": [
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.about.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.announcements.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.awards.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.contact.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.crops.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.crops.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.extras.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.extraterms.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.history.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.home.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.legal.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.nav.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.productfamilies.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.productfamily.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.products.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.products.plates.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.products.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.solutions.automation-control.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.solutions.field-crops.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.solutions.high-volume.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.solutions.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.solutions.mechanized-irrigation.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.solutions.non-agricultural-applications.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.solutions.nursery.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.solutions.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.solutions.sustainability.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.solutions.tree-and-vine.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.storymodules.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.webtools.json"
      ],
      "dir": "ltr"
    },
    {
      "code": "en",
      "language": "en",
      "name": "English",
      "files": [
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.about.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.announcements.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.awards.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.contact.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.crops.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.crops.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.extras.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.extraterms.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.history.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.home.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.legal.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.nav.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.productfamilies.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.productfamily.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.products.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.products.plates.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.products.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.solutions.automation-control.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.solutions.field-crops.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.solutions.high-volume.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.solutions.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.solutions.mechanized-irrigation.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.solutions.non-agricultural-applications.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.solutions.nursery.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.solutions.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.solutions.sustainability.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.solutions.tree-and-vine.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.storymodules.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.webtools.json"
      ],
      "dir": "ltr"
    },
    {
      "code": "es",
      "language": "es",
      "name": "Español",
      "files": [
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.about.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.announcements.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.awards.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.contact.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.crops.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.crops.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.extras.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.extraterms.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.history.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.home.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.legal.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.nav.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.productfamilies.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.productfamily.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.products.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.products.plates.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.products.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.solutions.automation-control.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.solutions.field-crops.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.solutions.high-volume.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.solutions.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.solutions.mechanized-irrigation.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.solutions.non-agricultural-applications.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.solutions.nursery.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.solutions.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.solutions.sustainability.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.solutions.tree-and-vine.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.storymodules.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.webtools.json"
      ],
      "dir": "ltr"
    },
    {
      "code": "fr",
      "language": "fr",
      "name": "Français",
      "files": [
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.about.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.announcements.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.awards.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.contact.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.crops.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.crops.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.extras.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.extraterms.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.history.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.home.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.legal.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.nav.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.productfamilies.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.productfamily.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.products.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.products.plates.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.products.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.solutions.automation-control.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.solutions.field-crops.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.solutions.high-volume.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.solutions.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.solutions.mechanized-irrigation.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.solutions.non-agricultural-applications.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.solutions.nursery.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.solutions.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.solutions.sustainability.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.solutions.tree-and-vine.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.storymodules.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.webtools.json"
      ],
      "dir": "ltr"
    },
    {
      "code": "hu",
      "language": "hu",
      "name": "Magyar",
      "files": [
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.about.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.announcements.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.awards.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.contact.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.crops.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.crops.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.extras.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.extraterms.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.history.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.home.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.legal.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.nav.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.productfamilies.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.productfamily.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.products.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.products.plates.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.products.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.solutions.automation-control.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.solutions.field-crops.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.solutions.high-volume.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.solutions.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.solutions.non-agricultural-applications.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.solutions.nursery.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.solutions.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.solutions.sustainability.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.solutions.tree-and-vine.json"
      ],
      "dir": "ltr"
    },
    {
      "code": "pt",
      "language": "pt",
      "name": "Português",
      "files": [
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.about.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.announcements.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.awards.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.contact.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.crops.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.crops.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.extras.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.extraterms.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.history.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.home.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.legal.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.nav.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.productfamilies.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.productfamily.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.products.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.products.plates.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.products.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.solutions.automation-control.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.solutions.field-crops.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.solutions.high-volume.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.solutions.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.solutions.mechanized-irrigation.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.solutions.non-agricultural-applications.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.solutions.nursery.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.solutions.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.solutions.sustainability.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.solutions.tree-and-vine.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.storymodules.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.webtools.json"
      ],
      "dir": "ltr"
    },
    {
      "code": "ru",
      "language": "ru",
      "name": "Pyccкий",
      "files": [
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.about.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.announcements.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.awards.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.contact.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.crops.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.crops.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.extras.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.extraterms.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.history.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.home.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.legal.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.nav.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.productfamilies.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.productfamily.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.products.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.products.plates.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.products.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.solutions.automation-control.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.solutions.field-crops.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.solutions.high-volume.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.solutions.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.solutions.mechanized-irrigation.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.solutions.non-agricultural-applications.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.solutions.nursery.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.solutions.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.solutions.sustainability.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.solutions.tree-and-vine.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.storymodules.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.webtools.json"
      ],
      "dir": "ltr"
    },
    {
      "code": "tr",
      "language": "tr",
      "name": "Türk",
      "files": [
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.about.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.announcements.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.awards.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.contact.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.crops.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.crops.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.extras.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.extraterms.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.history.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.home.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.legal.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.nav.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.productfamilies.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.productfamily.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.products.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.products.plates.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.products.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.solutions.automation-control.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.solutions.field-crops.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.solutions.high-volume.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.solutions.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.solutions.mechanized-irrigation.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.solutions.non-agricultural-applications.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.solutions.nursery.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.solutions.resources.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.solutions.sustainability.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.solutions.tree-and-vine.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.storymodules.json",
        "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.webtools.json"
      ],
      "dir": "ltr"
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": "./json/languages",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "ar",
    "language": "ar",
    "name": "العربية",
    "files": [
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.about.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.announcements.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.awards.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.contact.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.crops.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.crops.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.extras.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.extraterms.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.history.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.home.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.legal.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.nav.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.productfamilies.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.productfamily.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.products.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.products.plates.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.products.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.solutions.automation-control.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.solutions.field-crops.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.solutions.high-volume.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.solutions.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.solutions.mechanized-irrigation.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.solutions.non-agricultural-applications.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.solutions.nursery.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.solutions.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.solutions.sustainability.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.solutions.tree-and-vine.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.storymodules.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ar/ar.webtools.json"
      }
    ],
    "dir": "rtl"
  },
  {
    "code": "de",
    "language": "de",
    "name": "Deutsch",
    "files": [
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.about.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.announcements.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.awards.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.contact.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.crops.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.crops.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.extras.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.extraterms.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.history.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.home.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.legal.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.nav.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.productfamilies.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.productfamily.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.products.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.products.plates.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.products.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.solutions.automation-control.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.solutions.field-crops.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.solutions.high-volume.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.solutions.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.solutions.mechanized-irrigation.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.solutions.non-agricultural-applications.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.solutions.nursery.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.solutions.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.solutions.sustainability.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.solutions.tree-and-vine.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.storymodules.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/de/de.webtools.json"
      }
    ],
    "dir": "ltr"
  },
  {
    "code": "en",
    "language": "en",
    "name": "English",
    "files": [
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.about.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.announcements.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.awards.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.contact.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.crops.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.crops.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.extras.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.extraterms.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.history.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.home.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.legal.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.nav.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.productfamilies.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.productfamily.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.products.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.products.plates.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.products.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.solutions.automation-control.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.solutions.field-crops.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.solutions.high-volume.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.solutions.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.solutions.mechanized-irrigation.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.solutions.non-agricultural-applications.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.solutions.nursery.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.solutions.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.solutions.sustainability.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.solutions.tree-and-vine.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.storymodules.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/en/en.webtools.json"
      }
    ],
    "dir": "ltr"
  },
  {
    "code": "es",
    "language": "es",
    "name": "Español",
    "files": [
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.about.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.announcements.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.awards.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.contact.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.crops.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.crops.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.extras.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.extraterms.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.history.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.home.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.legal.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.nav.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.productfamilies.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.productfamily.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.products.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.products.plates.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.products.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.solutions.automation-control.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.solutions.field-crops.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.solutions.high-volume.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.solutions.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.solutions.mechanized-irrigation.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.solutions.non-agricultural-applications.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.solutions.nursery.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.solutions.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.solutions.sustainability.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.solutions.tree-and-vine.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.storymodules.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/es/es.webtools.json"
      }
    ],
    "dir": "ltr"
  },
  {
    "code": "fr",
    "language": "fr",
    "name": "Français",
    "files": [
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.about.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.announcements.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.awards.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.contact.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.crops.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.crops.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.extras.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.extraterms.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.history.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.home.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.legal.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.nav.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.productfamilies.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.productfamily.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.products.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.products.plates.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.products.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.solutions.automation-control.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.solutions.field-crops.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.solutions.high-volume.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.solutions.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.solutions.mechanized-irrigation.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.solutions.non-agricultural-applications.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.solutions.nursery.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.solutions.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.solutions.sustainability.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.solutions.tree-and-vine.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.storymodules.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/fr/fr.webtools.json"
      }
    ],
    "dir": "ltr"
  },
  {
    "code": "hu",
    "language": "hu",
    "name": "Magyar",
    "files": [
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.about.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.announcements.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.awards.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.contact.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.crops.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.crops.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.extras.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.extraterms.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.history.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.home.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.legal.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.nav.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.productfamilies.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.productfamily.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.products.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.products.plates.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.products.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.solutions.automation-control.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.solutions.field-crops.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.solutions.high-volume.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.solutions.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.solutions.non-agricultural-applications.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.solutions.nursery.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.solutions.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.solutions.sustainability.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/hu/hu.solutions.tree-and-vine.json"
      }
    ],
    "dir": "ltr"
  },
  {
    "code": "pt",
    "language": "pt",
    "name": "Português",
    "files": [
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.about.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.announcements.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.awards.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.contact.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.crops.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.crops.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.extras.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.extraterms.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.history.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.home.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.legal.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.nav.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.productfamilies.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.productfamily.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.products.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.products.plates.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.products.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.solutions.automation-control.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.solutions.field-crops.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.solutions.high-volume.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.solutions.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.solutions.mechanized-irrigation.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.solutions.non-agricultural-applications.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.solutions.nursery.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.solutions.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.solutions.sustainability.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.solutions.tree-and-vine.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.storymodules.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/pt/pt.webtools.json"
      }
    ],
    "dir": "ltr"
  },
  {
    "code": "ru",
    "language": "ru",
    "name": "Pyccкий",
    "files": [
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.about.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.announcements.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.awards.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.contact.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.crops.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.crops.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.extras.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.extraterms.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.history.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.home.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.legal.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.nav.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.productfamilies.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.productfamily.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.products.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.products.plates.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.products.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.solutions.automation-control.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.solutions.field-crops.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.solutions.high-volume.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.solutions.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.solutions.mechanized-irrigation.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.solutions.non-agricultural-applications.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.solutions.nursery.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.solutions.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.solutions.sustainability.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.solutions.tree-and-vine.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.storymodules.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/ru/ru.webtools.json"
      }
    ],
    "dir": "ltr"
  },
  {
    "code": "tr",
    "language": "tr",
    "name": "Türk",
    "files": [
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.about.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.announcements.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.awards.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.contact.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.crops.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.crops.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.extras.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.extraterms.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.history.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.home.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.legal.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.nav.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.productfamilies.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.productfamily.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.products.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.products.plates.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.products.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.solutions.automation-control.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.solutions.field-crops.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.solutions.high-volume.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.solutions.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.solutions.mechanized-irrigation.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.solutions.non-agricultural-applications.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.solutions.nursery.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.solutions.resources.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.solutions.sustainability.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.solutions.tree-and-vine.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.storymodules.json"
      },
      {
        "path": "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/json/languages/tr/tr.webtools.json"
      }
    ],
    "dir": "ltr"
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
