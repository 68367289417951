import { default as indexdqTfZA2n97Meta } from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/about/index.vue?macro=true";
import { default as _91id_93Snl9j9qBztMeta } from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/announcements/[id].vue?macro=true";
import { default as index0d7b7aIhnhMeta } from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/announcements/index.vue?macro=true";
import { default as indexxVfMJMs2URMeta } from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/awards/index.vue?macro=true";
import { default as indexuGU0IiqX1WMeta } from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/contact/index.vue?macro=true";
import { default as _91id_93Te1is85JM0Meta } from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/crops/[id].vue?macro=true";
import { default as indexi6xaeCmf3NMeta } from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/crops/index.vue?macro=true";
import { default as indexaGX6kTYzkOMeta } from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/dealers/index.vue?macro=true";
import { default as indexI6eWPqNQgEMeta } from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/history/index.vue?macro=true";
import { default as indexCm5DNUS2P3Meta } from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/index.vue?macro=true";
import { default as _91id_9335tmnBeAY5Meta } from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/legal/[id].vue?macro=true";
import { default as _91id_93xSa4pwslI6Meta } from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/products/[_subId]/[id].vue?macro=true";
import { default as indextjKXQ5j9ssMeta } from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/products/[_subId]/index.vue?macro=true";
import { default as index4s7XaQFLbHMeta } from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/resources/index.vue?macro=true";
import { default as _91id_93LILsEYtOuTMeta } from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/solutions/[_subId]/[id].vue?macro=true";
import { default as indexm3YnAseG1oMeta } from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/solutions/[_subId]/index.vue?macro=true";
import { default as indexgBfPNNKI6TMeta } from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/webtools/index.vue?macro=true";
import { default as component_45stubZOH9eMlQrpMeta } from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubZOH9eMlQrp } from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about___ar",
    path: "/ar/about",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___de",
    path: "/de/about",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___en",
    path: "/about",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___es",
    path: "/es/about",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___fr",
    path: "/fr/about",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___hu",
    path: "/hu/about",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___pt",
    path: "/pt/about",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___ru",
    path: "/ru/about",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___tr",
    path: "/tr/about",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "announcements-id___ar",
    path: "/ar/announcements/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/announcements/[id].vue").then(m => m.default || m)
  },
  {
    name: "announcements-id___de",
    path: "/de/announcements/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/announcements/[id].vue").then(m => m.default || m)
  },
  {
    name: "announcements-id___en",
    path: "/announcements/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/announcements/[id].vue").then(m => m.default || m)
  },
  {
    name: "announcements-id___es",
    path: "/es/announcements/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/announcements/[id].vue").then(m => m.default || m)
  },
  {
    name: "announcements-id___fr",
    path: "/fr/announcements/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/announcements/[id].vue").then(m => m.default || m)
  },
  {
    name: "announcements-id___hu",
    path: "/hu/announcements/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/announcements/[id].vue").then(m => m.default || m)
  },
  {
    name: "announcements-id___pt",
    path: "/pt/announcements/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/announcements/[id].vue").then(m => m.default || m)
  },
  {
    name: "announcements-id___ru",
    path: "/ru/announcements/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/announcements/[id].vue").then(m => m.default || m)
  },
  {
    name: "announcements-id___tr",
    path: "/tr/announcements/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/announcements/[id].vue").then(m => m.default || m)
  },
  {
    name: "announcements___ar",
    path: "/ar/announcements",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/announcements/index.vue").then(m => m.default || m)
  },
  {
    name: "announcements___de",
    path: "/de/announcements",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/announcements/index.vue").then(m => m.default || m)
  },
  {
    name: "announcements___en",
    path: "/announcements",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/announcements/index.vue").then(m => m.default || m)
  },
  {
    name: "announcements___es",
    path: "/es/announcements",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/announcements/index.vue").then(m => m.default || m)
  },
  {
    name: "announcements___fr",
    path: "/fr/announcements",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/announcements/index.vue").then(m => m.default || m)
  },
  {
    name: "announcements___hu",
    path: "/hu/announcements",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/announcements/index.vue").then(m => m.default || m)
  },
  {
    name: "announcements___pt",
    path: "/pt/announcements",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/announcements/index.vue").then(m => m.default || m)
  },
  {
    name: "announcements___ru",
    path: "/ru/announcements",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/announcements/index.vue").then(m => m.default || m)
  },
  {
    name: "announcements___tr",
    path: "/tr/announcements",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/announcements/index.vue").then(m => m.default || m)
  },
  {
    name: "awards___ar",
    path: "/ar/awards",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/awards/index.vue").then(m => m.default || m)
  },
  {
    name: "awards___de",
    path: "/de/awards",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/awards/index.vue").then(m => m.default || m)
  },
  {
    name: "awards___en",
    path: "/awards",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/awards/index.vue").then(m => m.default || m)
  },
  {
    name: "awards___es",
    path: "/es/awards",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/awards/index.vue").then(m => m.default || m)
  },
  {
    name: "awards___fr",
    path: "/fr/awards",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/awards/index.vue").then(m => m.default || m)
  },
  {
    name: "awards___hu",
    path: "/hu/awards",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/awards/index.vue").then(m => m.default || m)
  },
  {
    name: "awards___pt",
    path: "/pt/awards",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/awards/index.vue").then(m => m.default || m)
  },
  {
    name: "awards___ru",
    path: "/ru/awards",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/awards/index.vue").then(m => m.default || m)
  },
  {
    name: "awards___tr",
    path: "/tr/awards",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/awards/index.vue").then(m => m.default || m)
  },
  {
    name: "contact___ar",
    path: "/ar/contact",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "contact___de",
    path: "/de/contact",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/contact",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "contact___es",
    path: "/es/contact",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "contact___fr",
    path: "/fr/contact",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "contact___hu",
    path: "/hu/contact",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "contact___pt",
    path: "/pt/contact",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "contact___ru",
    path: "/ru/contact",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "contact___tr",
    path: "/tr/contact",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "crops-id___ar",
    path: "/ar/crops/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/crops/[id].vue").then(m => m.default || m)
  },
  {
    name: "crops-id___de",
    path: "/de/crops/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/crops/[id].vue").then(m => m.default || m)
  },
  {
    name: "crops-id___en",
    path: "/crops/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/crops/[id].vue").then(m => m.default || m)
  },
  {
    name: "crops-id___es",
    path: "/es/crops/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/crops/[id].vue").then(m => m.default || m)
  },
  {
    name: "crops-id___fr",
    path: "/fr/crops/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/crops/[id].vue").then(m => m.default || m)
  },
  {
    name: "crops-id___hu",
    path: "/hu/crops/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/crops/[id].vue").then(m => m.default || m)
  },
  {
    name: "crops-id___pt",
    path: "/pt/crops/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/crops/[id].vue").then(m => m.default || m)
  },
  {
    name: "crops-id___ru",
    path: "/ru/crops/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/crops/[id].vue").then(m => m.default || m)
  },
  {
    name: "crops-id___tr",
    path: "/tr/crops/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/crops/[id].vue").then(m => m.default || m)
  },
  {
    name: "crops___ar",
    path: "/ar/crops",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/crops/index.vue").then(m => m.default || m)
  },
  {
    name: "crops___de",
    path: "/de/crops",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/crops/index.vue").then(m => m.default || m)
  },
  {
    name: "crops___en",
    path: "/crops",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/crops/index.vue").then(m => m.default || m)
  },
  {
    name: "crops___es",
    path: "/es/crops",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/crops/index.vue").then(m => m.default || m)
  },
  {
    name: "crops___fr",
    path: "/fr/crops",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/crops/index.vue").then(m => m.default || m)
  },
  {
    name: "crops___hu",
    path: "/hu/crops",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/crops/index.vue").then(m => m.default || m)
  },
  {
    name: "crops___pt",
    path: "/pt/crops",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/crops/index.vue").then(m => m.default || m)
  },
  {
    name: "crops___ru",
    path: "/ru/crops",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/crops/index.vue").then(m => m.default || m)
  },
  {
    name: "crops___tr",
    path: "/tr/crops",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/crops/index.vue").then(m => m.default || m)
  },
  {
    name: "dealers___ar",
    path: "/ar/dealers",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/dealers/index.vue").then(m => m.default || m)
  },
  {
    name: "dealers___de",
    path: "/de/dealers",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/dealers/index.vue").then(m => m.default || m)
  },
  {
    name: "dealers___en",
    path: "/dealers",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/dealers/index.vue").then(m => m.default || m)
  },
  {
    name: "dealers___es",
    path: "/es/dealers",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/dealers/index.vue").then(m => m.default || m)
  },
  {
    name: "dealers___fr",
    path: "/fr/dealers",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/dealers/index.vue").then(m => m.default || m)
  },
  {
    name: "dealers___hu",
    path: "/hu/dealers",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/dealers/index.vue").then(m => m.default || m)
  },
  {
    name: "dealers___pt",
    path: "/pt/dealers",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/dealers/index.vue").then(m => m.default || m)
  },
  {
    name: "dealers___ru",
    path: "/ru/dealers",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/dealers/index.vue").then(m => m.default || m)
  },
  {
    name: "dealers___tr",
    path: "/tr/dealers",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/dealers/index.vue").then(m => m.default || m)
  },
  {
    name: "history___ar",
    path: "/ar/history",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/history/index.vue").then(m => m.default || m)
  },
  {
    name: "history___de",
    path: "/de/history",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/history/index.vue").then(m => m.default || m)
  },
  {
    name: "history___en",
    path: "/history",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/history/index.vue").then(m => m.default || m)
  },
  {
    name: "history___es",
    path: "/es/history",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/history/index.vue").then(m => m.default || m)
  },
  {
    name: "history___fr",
    path: "/fr/history",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/history/index.vue").then(m => m.default || m)
  },
  {
    name: "history___hu",
    path: "/hu/history",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/history/index.vue").then(m => m.default || m)
  },
  {
    name: "history___pt",
    path: "/pt/history",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/history/index.vue").then(m => m.default || m)
  },
  {
    name: "history___ru",
    path: "/ru/history",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/history/index.vue").then(m => m.default || m)
  },
  {
    name: "history___tr",
    path: "/tr/history",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/history/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ar",
    path: "/ar",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___hu",
    path: "/hu",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pt",
    path: "/pt",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ru",
    path: "/ru",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___tr",
    path: "/tr",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-id___ar",
    path: "/ar/legal/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/legal/[id].vue").then(m => m.default || m)
  },
  {
    name: "legal-id___de",
    path: "/de/legal/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/legal/[id].vue").then(m => m.default || m)
  },
  {
    name: "legal-id___en",
    path: "/legal/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/legal/[id].vue").then(m => m.default || m)
  },
  {
    name: "legal-id___es",
    path: "/es/legal/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/legal/[id].vue").then(m => m.default || m)
  },
  {
    name: "legal-id___fr",
    path: "/fr/legal/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/legal/[id].vue").then(m => m.default || m)
  },
  {
    name: "legal-id___hu",
    path: "/hu/legal/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/legal/[id].vue").then(m => m.default || m)
  },
  {
    name: "legal-id___pt",
    path: "/pt/legal/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/legal/[id].vue").then(m => m.default || m)
  },
  {
    name: "legal-id___ru",
    path: "/ru/legal/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/legal/[id].vue").then(m => m.default || m)
  },
  {
    name: "legal-id___tr",
    path: "/tr/legal/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/legal/[id].vue").then(m => m.default || m)
  },
  {
    name: "products-_subId-id___ar",
    path: "/ar/products/:_subId()/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/products/[_subId]/[id].vue").then(m => m.default || m)
  },
  {
    name: "products-_subId-id___de",
    path: "/de/products/:_subId()/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/products/[_subId]/[id].vue").then(m => m.default || m)
  },
  {
    name: "products-_subId-id___en",
    path: "/products/:_subId()/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/products/[_subId]/[id].vue").then(m => m.default || m)
  },
  {
    name: "products-_subId-id___es",
    path: "/es/products/:_subId()/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/products/[_subId]/[id].vue").then(m => m.default || m)
  },
  {
    name: "products-_subId-id___fr",
    path: "/fr/products/:_subId()/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/products/[_subId]/[id].vue").then(m => m.default || m)
  },
  {
    name: "products-_subId-id___hu",
    path: "/hu/products/:_subId()/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/products/[_subId]/[id].vue").then(m => m.default || m)
  },
  {
    name: "products-_subId-id___pt",
    path: "/pt/products/:_subId()/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/products/[_subId]/[id].vue").then(m => m.default || m)
  },
  {
    name: "products-_subId-id___ru",
    path: "/ru/products/:_subId()/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/products/[_subId]/[id].vue").then(m => m.default || m)
  },
  {
    name: "products-_subId-id___tr",
    path: "/tr/products/:_subId()/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/products/[_subId]/[id].vue").then(m => m.default || m)
  },
  {
    name: "products-_subId___ar",
    path: "/ar/products/:_subId()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/products/[_subId]/index.vue").then(m => m.default || m)
  },
  {
    name: "products-_subId___de",
    path: "/de/products/:_subId()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/products/[_subId]/index.vue").then(m => m.default || m)
  },
  {
    name: "products-_subId___en",
    path: "/products/:_subId()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/products/[_subId]/index.vue").then(m => m.default || m)
  },
  {
    name: "products-_subId___es",
    path: "/es/products/:_subId()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/products/[_subId]/index.vue").then(m => m.default || m)
  },
  {
    name: "products-_subId___fr",
    path: "/fr/products/:_subId()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/products/[_subId]/index.vue").then(m => m.default || m)
  },
  {
    name: "products-_subId___hu",
    path: "/hu/products/:_subId()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/products/[_subId]/index.vue").then(m => m.default || m)
  },
  {
    name: "products-_subId___pt",
    path: "/pt/products/:_subId()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/products/[_subId]/index.vue").then(m => m.default || m)
  },
  {
    name: "products-_subId___ru",
    path: "/ru/products/:_subId()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/products/[_subId]/index.vue").then(m => m.default || m)
  },
  {
    name: "products-_subId___tr",
    path: "/tr/products/:_subId()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/products/[_subId]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources___ar",
    path: "/ar/resources",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: "resources___de",
    path: "/de/resources",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: "resources___en",
    path: "/resources",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: "resources___es",
    path: "/es/resources",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: "resources___fr",
    path: "/fr/resources",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: "resources___hu",
    path: "/hu/resources",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: "resources___pt",
    path: "/pt/resources",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: "resources___ru",
    path: "/ru/resources",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: "resources___tr",
    path: "/tr/resources",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: "solutions-_subId-id___ar",
    path: "/ar/solutions/:_subId()/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/solutions/[_subId]/[id].vue").then(m => m.default || m)
  },
  {
    name: "solutions-_subId-id___de",
    path: "/de/solutions/:_subId()/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/solutions/[_subId]/[id].vue").then(m => m.default || m)
  },
  {
    name: "solutions-_subId-id___en",
    path: "/solutions/:_subId()/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/solutions/[_subId]/[id].vue").then(m => m.default || m)
  },
  {
    name: "solutions-_subId-id___es",
    path: "/es/solutions/:_subId()/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/solutions/[_subId]/[id].vue").then(m => m.default || m)
  },
  {
    name: "solutions-_subId-id___fr",
    path: "/fr/solutions/:_subId()/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/solutions/[_subId]/[id].vue").then(m => m.default || m)
  },
  {
    name: "solutions-_subId-id___hu",
    path: "/hu/solutions/:_subId()/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/solutions/[_subId]/[id].vue").then(m => m.default || m)
  },
  {
    name: "solutions-_subId-id___pt",
    path: "/pt/solutions/:_subId()/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/solutions/[_subId]/[id].vue").then(m => m.default || m)
  },
  {
    name: "solutions-_subId-id___ru",
    path: "/ru/solutions/:_subId()/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/solutions/[_subId]/[id].vue").then(m => m.default || m)
  },
  {
    name: "solutions-_subId-id___tr",
    path: "/tr/solutions/:_subId()/:id()",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/solutions/[_subId]/[id].vue").then(m => m.default || m)
  },
  {
    name: "solutions-_subId___ar",
    path: "/ar/solutions/:_subId()",
    meta: indexm3YnAseG1oMeta || {},
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/solutions/[_subId]/index.vue").then(m => m.default || m)
  },
  {
    name: "solutions-_subId___de",
    path: "/de/solutions/:_subId()",
    meta: indexm3YnAseG1oMeta || {},
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/solutions/[_subId]/index.vue").then(m => m.default || m)
  },
  {
    name: "solutions-_subId___en",
    path: "/solutions/:_subId()",
    meta: indexm3YnAseG1oMeta || {},
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/solutions/[_subId]/index.vue").then(m => m.default || m)
  },
  {
    name: "solutions-_subId___es",
    path: "/es/solutions/:_subId()",
    meta: indexm3YnAseG1oMeta || {},
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/solutions/[_subId]/index.vue").then(m => m.default || m)
  },
  {
    name: "solutions-_subId___fr",
    path: "/fr/solutions/:_subId()",
    meta: indexm3YnAseG1oMeta || {},
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/solutions/[_subId]/index.vue").then(m => m.default || m)
  },
  {
    name: "solutions-_subId___hu",
    path: "/hu/solutions/:_subId()",
    meta: indexm3YnAseG1oMeta || {},
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/solutions/[_subId]/index.vue").then(m => m.default || m)
  },
  {
    name: "solutions-_subId___pt",
    path: "/pt/solutions/:_subId()",
    meta: indexm3YnAseG1oMeta || {},
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/solutions/[_subId]/index.vue").then(m => m.default || m)
  },
  {
    name: "solutions-_subId___ru",
    path: "/ru/solutions/:_subId()",
    meta: indexm3YnAseG1oMeta || {},
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/solutions/[_subId]/index.vue").then(m => m.default || m)
  },
  {
    name: "solutions-_subId___tr",
    path: "/tr/solutions/:_subId()",
    meta: indexm3YnAseG1oMeta || {},
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/solutions/[_subId]/index.vue").then(m => m.default || m)
  },
  {
    name: "webtools___ar",
    path: "/ar/webtools",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/webtools/index.vue").then(m => m.default || m)
  },
  {
    name: "webtools___de",
    path: "/de/webtools",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/webtools/index.vue").then(m => m.default || m)
  },
  {
    name: "webtools___en",
    path: "/webtools",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/webtools/index.vue").then(m => m.default || m)
  },
  {
    name: "webtools___es",
    path: "/es/webtools",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/webtools/index.vue").then(m => m.default || m)
  },
  {
    name: "webtools___fr",
    path: "/fr/webtools",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/webtools/index.vue").then(m => m.default || m)
  },
  {
    name: "webtools___hu",
    path: "/hu/webtools",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/webtools/index.vue").then(m => m.default || m)
  },
  {
    name: "webtools___pt",
    path: "/pt/webtools",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/webtools/index.vue").then(m => m.default || m)
  },
  {
    name: "webtools___ru",
    path: "/ru/webtools",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/webtools/index.vue").then(m => m.default || m)
  },
  {
    name: "webtools___tr",
    path: "/tr/webtools",
    component: () => import("C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/pages/webtools/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubZOH9eMlQrpMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubZOH9eMlQrp
  },
  {
    name: component_45stubZOH9eMlQrpMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubZOH9eMlQrp
  },
  {
    name: component_45stubZOH9eMlQrpMeta?.name,
    path: "/ar-sitemap.xml",
    component: component_45stubZOH9eMlQrp
  },
  {
    name: component_45stubZOH9eMlQrpMeta?.name,
    path: "/de-sitemap.xml",
    component: component_45stubZOH9eMlQrp
  },
  {
    name: component_45stubZOH9eMlQrpMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubZOH9eMlQrp
  },
  {
    name: component_45stubZOH9eMlQrpMeta?.name,
    path: "/es-sitemap.xml",
    component: component_45stubZOH9eMlQrp
  },
  {
    name: component_45stubZOH9eMlQrpMeta?.name,
    path: "/fr-sitemap.xml",
    component: component_45stubZOH9eMlQrp
  },
  {
    name: component_45stubZOH9eMlQrpMeta?.name,
    path: "/hu-sitemap.xml",
    component: component_45stubZOH9eMlQrp
  },
  {
    name: component_45stubZOH9eMlQrpMeta?.name,
    path: "/pt-sitemap.xml",
    component: component_45stubZOH9eMlQrp
  },
  {
    name: component_45stubZOH9eMlQrpMeta?.name,
    path: "/ru-sitemap.xml",
    component: component_45stubZOH9eMlQrp
  },
  {
    name: component_45stubZOH9eMlQrpMeta?.name,
    path: "/tr-sitemap.xml",
    component: component_45stubZOH9eMlQrp
  }
]