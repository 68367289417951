<script setup>
const error = useError();

const handleError = () => {
    clearError({
        redirect: '/',
    });
};
</script>
<template>
    <NuxtLayout>
        <div class="error-container">
            <template v-if="error.statusCode === 404">
                <h1>Page Not Found</h1>
                <p>Sorry, that page doesn't exist.</p>
            </template>
            <template v-else>
                <h1>Whoops!</h1>
                <p>
                    <strong>{{ error.message }}</strong>
                </p>
                <p>It looks like something broke.</p>
                <p>Sorry about that.</p>
            </template>
            <p>
                Click here 
                <a @click="handleError">
                    here
                </a>
                to go to the home page or use the menu above to select a new page.
            </p>
        </div>
    </NuxtLayout>
</template>
<style scoped>
.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 25vh;
}
</style>