<script setup>
const head = useLocaleHead({
  addDirAttribute: true,
  addSeoAttributes: true,
  identifierAttribute: 'id',
});
const htmlAttrs = computed(() => !head.value.htmlAttrs);
onErrorCaptured((err, instance, info) => {
  // Handle the error here
  console.error('Error in translation:', err);
  return true; // Prevent the error from propagating further
});
</script>

<template>
  <NuxtErrorBoundary @error="onErrorCaptured">
    <html :lang="htmlAttrs.code" :dir="htmlAttrs.dir">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    </html>
  </NuxtErrorBoundary>
</template>
