import revive_payload_client_4sVQNw7RlN from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_dw2T9lEw4h from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_fcc0iSNKKy from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_izaWKZ8rEu from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_client_UYiXMU8ZyN from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_8BKffvaEi5 from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import jquery_SqE5xeO2Mp from "C:/Users/brettp/Documents/Workspaces/nelsonweb-nuxt/plugins/jquery.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  titles_dw2T9lEw4h,
  defaultsWaitI18n_fcc0iSNKKy,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  plugin_client_UYiXMU8ZyN,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  jquery_SqE5xeO2Mp
]